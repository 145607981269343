import React from 'react'
import Layout from '../layouts'
import OnSite from '../images/On-site-sales.jpg'
import Collapsible from 'react-collapsible'

const GenesisSales = () => (
	<Layout head={{ title: 'On-Site Sales Discovery' }}>
		<div className="gsp">
			<div
				id="hero-section"
				className="bg-cover"
				style={{ background: `url("${OnSite}")` }}
			>
				<div className="container">
					<div className="slim-content">
						<h1 className="text-red">On-Site Sales Discovery</h1>
						<p className="py-4">
							On-site Sales Discovery is a comprehensive evaluation of your
							readiness to meet your sales objectives, followed by actionable,
							prioritized recommendations to improve sales productivity.
						</p>
					</div>
				</div>
			</div>
			<div className="container py-5">
				<h3 className="text-red">
					Why do our clients choose to have an On-Site Sales Discovery?
				</h3>
				<p>
					Each of our clients wants their sales organization to be better, but
					they often lack the experience, time and resources to do so.
					Regardless of the business pain, our clients are seeking help to
					understand the nature of the problem and how to improve.
				</p>
				<h3 className="text-red">
					They all experience some of the following common sales business pains:
				</h3>
				<ul>
					<li>Inconsistent ability to “make the numbers”</li>
					<li>An inefficient sales team structure</li>
					<li>Lack of a formal sales process</li>
					<li>The inability to accurately forecast future sales</li>
					<li>
						High turnover of sales personnel or inability to find the best
						salespeople
					</li>
					<li>
						A compensation plan that doesn’t result in the desired behavior
					</li>
					<li>
						Inability to penetrate new markets or introduce new products to
						existing markets
					</li>
					<li>Inability to track activity and crucial client information</li>
					<li>
						Not effectively able to articulate the value proposition, solution
						or service
					</li>
					<li>
						Sales leadership lacks the ability to coach and fine tune sales
						performance
					</li>
				</ul>

				<h3>
					<span className="text-red">On-site Sales Discovery</span> evaluates
					the following key drivers of sales effectiveness:
				</h3>

				<div className="bg-grey my-4">
					<Collapsible trigger="Sales Strategy">
						<ul className="circle-chevron">
							<li>Targeted customers</li>
							<li>Targeted decision makers by role</li>
							<li>Referral partners needed</li>
							<li>Value proposition and points of differentiation</li>
							<li>Prospecting strategy</li>
						</ul>
					</Collapsible>
					<Collapsible trigger="Sales Process">
						<ul className="circle-chevron">
							<li>Sales coverage model</li>
							<li>Sales process</li>
							<li>Sales process</li>
						</ul>
					</Collapsible>
					<Collapsible trigger="Sales Management">
						<ul className="circle-chevron">
							<li>Sales goals & quotas</li>
							<li>Metrics, reporting & dashboards</li>
							<li>Compensation & incentive plan</li>
						</ul>
					</Collapsible>
					<Collapsible trigger="Hiring Plan">
						<ul className="circle-chevron">
							<li>Defined roles & responsibilities</li>
							<li>Staffing & hiring plans</li>
							<li>Sales training programs</li>
						</ul>
					</Collapsible>
				</div>

				<p>
					On-site Sales Discovery is based on a proven methodology, working
					closely with your senior management team to provide an objective
					assessment of the key drivers of sales organization productivity. We
					then develop a prioritized action plan and recommendations on how to
					improve the sales performance of your organization.
				</p>
			</div>
		</div>
	</Layout>
)

export default GenesisSales
